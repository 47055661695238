/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    color: var(--oksijen-turuncu);
    .count-box {
        padding: 30px 30px 25px 30px;
        width: 100%;
        height: 12rem;
        position: relative;
        text-align: center;
        box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        background-color: var(--oksijen-mavi);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5;
        .count-box-icon{
            position: absolute;
            width: 64px;
            height: 64px;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            background-color: var(--oksijen-body);
            border-radius: 50px;
            border: 2px solid var(--oksijen-body);
            box-shadow: 0px 0px 1px 5px var(--oksijen-turuncu);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            i {
                color: var(--oksijen-turuncu);
            }
        }
        span {
            font-size: 36px;
            display: block;
            font-weight: bold;
        }
        p {
            padding: 0;
            margin: 0;
            font-family: "Raleway", sans-serif;
            font-size: 16px;
            font-weight: 700;
            white-space: normal;
            text-overflow: ellipsis;
        }
    }
}
